import React from "react";
import code from "../assets/code.avif";
const Main = () => {
  return (
    <div className="px-5 sm:m-auto container pt-20 w-full lg:w-[60rem]">
      <div className="text-4xl font-bold text-textcolor font-inter">
        Hi 👋 I'm Pravin!
      </div>
      <div className="pt-5 text-2xl font-semibold text-textcolor font-inter">
        As a Information Technology graduate fascinated by software
        craftsmanship, I'm excited to share my portfolio.
      </div>
      <div className="text-md font-inter text-textcolor py-4 space-y-2 ">
       
        <div className="font-bold">🖥️ GET @ Jio Platforms Limited</div>
      </div>
      <div className="text-md font-inter text-textcolor py-3 space-y-2">
        <div className="">📍 Live in Navi Mumbai, Maharashtra</div>
        <div className="">🌎 Speaks English, Hindi, Marathi </div>
        <div className="">📬 nichal.pravin2001@gmail.com</div>
      </div>
      <div className="px-10">
        <img src={code} alt="" />
      </div>
      {/* Experience */}
      <div className=" text-textcolor font-inter">
        <div className="pt-5 text-xl font-semibold italic">Experience</div>
        <hr class="dashed"></hr>
        <hr className="dashed border-dashed mx-4"></hr>
        <div className="flex py-2">
          <div className="flex items-center justify-center pr-2">💻</div>
          <div className="font-medium pb-1">
            <div className="font-bold">Graduate Engineer Trainee @ Jio</div>
            <div className="text-sm">
              <span className="italic"> Oct 2023 - Present&nbsp; </span>
           
            </div>
          </div>
        </div>
        <div className="flex py-2">
          <div className="flex items-center justify-center pr-2">💻</div>
          <div className="font-medium">
            <div className="font-bold"> Software Developer @ Grow90</div>
            <div className="text-sm ">
              <span className="italic">Jun 2022 - Oct 2022&nbsp; </span>

              <a
                href="https://drive.google.com/file/d/1_UFNckEyTwLaJ_gazySGJ70rksUhH7w4/view?usp=sharing"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="font-normal text-xs text-black-600  bg-green-200 p-1 rounded-md">
                  🔗 Complition Letter
                </span>
              </a>
            </div>
          </div>
        </div>
        <hr className="dashed border-dashed mx-4"></hr>
        <div className="flex py-2">
          <div className="flex items-center justify-center pr-2">💻</div>
          <div className="font-medium">
            <div className="font-bold pb-1">Flutter Developer @ Apartmint Solutions</div>
            <div className="text-sm ">
              <span className="italic">Dec 2021 - Jan 2022&nbsp; </span>

              <a
                href="https://drive.google.com/file/d/1SaoA1Fg9gcv3n3ewzUmL_0BRR1I_O-b0/view?usp=sharing"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="font-normal text-xs text-black-600  bg-green-200 p-1 rounded-md">
                  🔗 Complition Letter
                </span>
              </a>
            </div>
          </div>
        </div>
       
      </div>
      {/* Projects */}
      <div className=" text-textcolor font-inter">
        <div className="pt-5 text-xl font-semibold italic">Projects</div>
        <hr class="dashed"></hr>
          <div className="flex py-2">
          <div className="flex pt-1 pr-2">🚀</div>
          <div className="font-medium">
            <div> voyagerflick - express clone made using TCP server </div>
            <div className="text-sm">
            
              <ul className="list-disc ml-7 space-y-1	">
                <li>VoyagerFlick is a personal project that implements an HTTP server using TCP sockets.</li>
                <li>
                Developed for learning or experimental purposes.
                </li>
                <li>Provides a simple implementation of HTTP server functionality over TCP.</li>
                <li>Written in TypeScript and available on npm for exploration and experimentation.</li>

              </ul>
              <div className="flex space-x-1 mt-1"> 
                <div className="pt-1 pb-1 ">
                
                <a
                  href="https://github.com/pjnichal/voyagerflick"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span className="font-normal ml-1 pl-1 pr-2 py-1 text-sm text-white    bg-gray-400  rounded-md">
                  🔗 Github Link
                  </span>
                </a>
              </div>
              <div className="pt-1 pb-1 ">
                
                <a
                  href="https://www.npmjs.com/package/voyagerflick"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span className="font-normal ml-1 pl-1 pr-2 py-1 text-sm text-white    bg-gray-400  rounded-md">
                  🔗 npm
                  </span>
                </a>
              </div>
              </div>
              
            </div>
          </div>
        </div>
         <hr className="dashed border-dashed mx-4"></hr>
         <div className="flex py-2">
          <div className="flex pt-1 pr-2">🚀</div>
          <div className="font-medium">
            <div> TypeScript Interpreter</div>
            <div className="text-sm">
              <ul className="list-disc ml-7 space-y-1	">
                <li>Developed a programming language interpreter entirely from scratch using TypeScript.</li>
                <li>
                Implemented lexer and parser components to tokenize source code and generate an abstract syntax tree (AST).
                </li>
                <li>Designed and executed interpreter functionality to traverse the AST and execute language constructs.</li>
                <li>Demonstrated proficiency in language design, parsing techniques, and interpreter architecture.</li>
                <li>Enhanced skills in TypeScript programming, software design, and problem-solving.</li>
              </ul>
              <div className="pt-1 pb-1  mt-1 ">
                
                <a
                  href="https://github.com/pjnichal/programming-language-from-scratch"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span className="font-normal ml-1 pl-1 pr-2 py-1 text-sm text-white    bg-gray-400  rounded-md">
                  🔗 Github Link
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr className="dashed border-dashed mx-4"></hr>
        <div className="flex py-2">
          <div className="flex pt-1 pr-2">🚀</div>
          <div className="font-medium">
            <div> Diplopedia: MSBTE Resources</div>
            <div className="text-sm">
              <div>
                application aims to help diploma students in their daily
                academics.
              </div>
              <div className="font-semibold">Technologies Used</div>
              <ul className="list-disc ml-7 space-y-1	">
                <li>Flutter framework for mobile app</li>
                <li>
                  RestApi using Node.js (express.js) and JWT for authentication
                </li>
                <li>MongoDB database</li>
                <li>Nginx server running on DigitalOcean droplet</li>
                <li>DigitalOcean Spaces for storage</li>
              </ul>
              <div className="pt-1 pb-1   mt-1">
                
                <a
                  href="https://drive.google.com/file/d/1Hj32sSZn2Yhz6dqEgcPaaN0uVOUeF9hp/view"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span className="font-normal ml-1 pl-1 pr-2 py-1 text-sm text-white    bg-gray-400  rounded-md">
                    🎥 Demo Video
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr className="dashed border-dashed mx-4"></hr>
        <div className="flex py-2">
          <div className="flex pt-1 pr-2">🚀</div>
          <div className="font-medium">
            <div> Raspberry Pi Torrent Mobile App</div>
            <div className="text-sm">
              <div>
                mobile app for controling transmission torrent client on
                raspberry pi from anywhere.
              </div>
              <div className="font-semibold">Technologies Used</div>
              <ul className="list-disc ml-7	pb-2 space-y-1">
                <li>Flutter for mobile app</li>
                <li>Node.js with express and python for backend</li>
                <li>Firebase realtime database</li>
                <li>Raspberry Pi 3 Model B+</li>
              </ul>

              <div className=" mt-1 ">
                <a
                  href="https://www.linkedin.com/posts/pravinnichal_flutter-iot-raspberrypi-activity-6830500834908938241-LtAR"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span className="font-normal  text-sm text-white  pl-1 pr-2 py-1  bg-gray-400  rounded-md">
                    🔗 LinkedIn Post
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Skills */}
      <div className=" text-textcolor font-inter">
        <div className="pt-5 text-xl font-semibold italic">Skills</div>
        <hr class="dashed"></hr>
        <div className="pt-2  grid grid-cols-4 gap-y-1 gap-x-2 grid-rows-2 gap-0  md:grid-cols-7 lg:grid-cols-7	">
          <span className="font-normal text-center text-sm text-white  py-1  bg-gray-500  rounded-md">
            Flutter
          </span>

          <span className="font-normal text-center text-sm text-white   py-1  bg-gray-500  rounded-md">
            Node.js
          </span>
          <span className="font-normal text-center text-sm text-white   py-1  bg-gray-500  rounded-md">
            Express
          </span>

          <span className="font-normal text-center text-sm text-white   py-1  bg-gray-500  rounded-md">
            Heroku
          </span>
          <span className="font-normal text-center text-sm text-white   py-1  bg-gray-500  rounded-md">
            ReactJS
          </span>

          <span className="font-normal text-center text-sm text-white   py-1  bg-gray-500  rounded-md">
            NoSQL
          </span>
          <span className="hidden md:flex"></span>
          <span className="font-normal text-center text-sm text-white   py-1  bg-gray-500  rounded-md">
            SQL
          </span>
          <span className="font-normal text-center text-sm text-white   py-1  bg-gray-500  rounded-md">
            Java
          </span>
          <span className="font-normal text-center text-sm text-white   py-1  bg-gray-500  rounded-md">
            Firebase
          </span>
          <span className="font-normal text-center text-sm text-white   py-1  bg-gray-500  rounded-md">
            DevOps
          </span>

          <span className="font-normal text-center text-sm col-span-2 sm:col-span-1 mr-[3rem] sm:mr-0 text-white   py-1  bg-gray-500  rounded-md">
            Tailwind CSS
          </span>
        </div>
      </div>
      {/* Socials */}
      <div className="pt-4 font-inter font-medium text-textcolor">
        <div className=" space-x-2">
          <a
            href="https://www.linkedin.com/in/pravin-nichal-93080b1b4/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span className="text-textcolor underline underline-offset-1">
              LinkedIn
            </span>
          </a>
          <a
            href="https://github.com/pjnichal"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span className="text-textcolor underline underline-offset-1">
              GitHub
            </span>
          </a>
          <a
            href="https://drive.google.com/file/d/1SmntCNiPaGNLwo56B13xvZmKhAOT6muf/view?usp=sharing"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span className="text-textcolor underline underline-offset-1">
              Resume
            </span>
          </a>
        </div>
      </div>

      {/* Footer */}
      <div className="pt-28"></div>
    </div>
  );
};

export default Main;
